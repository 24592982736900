import {Exceptions} from '../exceptions/exceptions.class';
import {HtmlContent, PrismicImage} from '../cms/cms.types';
import {Order} from '../orders/order.class';
import {PriceObject, WebPrice} from '../pricing/price-object.class';
import {Result} from '../search/search-result/search-result.class';
import {ShareLinkRecipient} from '../share-link/share-link.class';
import {uniq} from 'lodash';
import {User} from '../users/user.class';

export const prettyItemIDCaptureGroup = `([a-z0-9][0-9]{0,5}-?[0-9]?)`;
export const SHIPPING_SAVER = `8000000`;
export const SHIPPING_SAVER_PRICE = 119;
export const uglyItemIDCaptureGroup = `([a-z0-9][0-9]{6})`;
export type CrossRefLookupSource = `backsheet`;
export type WebcatSplitPosition = `Top` | `Bottom`;

export interface AllBrands {
    numbers: BrandLink[];
    A: BrandLink[];
    B: BrandLink[];
    C: BrandLink[];
    D: BrandLink[];
    E: BrandLink[];
    F: BrandLink[];
    G: BrandLink[];
    H: BrandLink[];
    I: BrandLink[];
    J: BrandLink[];
    K: BrandLink[];
    L: BrandLink[];
    M: BrandLink[];
    N: BrandLink[];
    O: BrandLink[];
    P: BrandLink[];
    Q: BrandLink[];
    R: BrandLink[];
    S: BrandLink[];
    T: BrandLink[];
    U: BrandLink[];
    V: BrandLink[];
    W: BrandLink[];
    X: BrandLink[];
    Y: BrandLink[];
    Z: BrandLink[];
}

export interface AssortmentItem {
    binQty: number;
    canShipAir?: boolean;
    exceptions: Exceptions;
    fullWebcat: string;
    categoryPath: string;
    id: string;
    image: string;
    imagePath: string;
    lotTrack: boolean;
    name: string;
    ormd: boolean;
    prettyid: string;
    price?: string;
    priceC?: number;
    priceInfo?: PriceObject;
    prop65Message?: string;
    reorderQty: number;
    sdsLink?: string;
    upsHazard: boolean;
    webcatName: string;
    xrefList: XrefItem[];
}

export interface BrandLink {
    hidden?: boolean;
    id: string;
    name: string;
    slug: string;
}

export interface BrandLetter {
    brandLinks: BrandLink[];
    name: string;
    numHidden?: number;
}

export interface Breadcrumbs {
    categoryPath: string;
    href: string;
    name: string;
}

export interface CardItem {
    active?: boolean;
    amtDiff?: number;
    bin?: string;
    binQty?: number;
    brand?: string;
    categoryPath?: string;
    checked?: boolean;
    comment?: string;
    compPrice?: number;
    description?: any;
    discontinued?: boolean;
    fullWebcat?: string;
    group?: number;
    hidden?: boolean;
    id?: string;
    imagePath?: string;
    inStock?: boolean;
    interval?: string;
    invnGroup?: string;
    invoiceNumber?: string;
    item?: string;
    lastInvcDt?: string;
    name?: string;
    orderCount?: number;
    orderDate?: string;
    orderNumber?: string;
    packageQuantity?: number;
    pctDiff?: number;
    pkgQty?: number;
    prettyid?: string | undefined;
    prettyItem?: string;
    price?: string | PriceObject;
    priceC?: number;
    priceInfo?: any;
    qtyLimit?: number;
    qtyOrd?: number;
    quantity?: number;
    replaced?: boolean;
    shortdesc?: any;
    uid?: string;
    units?: number;
    unitsOrdered?: number;
    webPrice?: any;
    wid?: number;
    widDescription?: string;
}

export interface CompareItem {
    imagePath: string;
    itemNum: string;
}

export interface CompareItemsRes {
    orderableItems: OrderableItem[];
    specs: ComparedSpec[];
}

export interface ComparedSpec {
    name: string;
    values: string[];
}

export interface CrossRefLookupItem {
    image: string;
    imagePath: string;
    item: string;
    name: string;
    pkgQty: number;
    prettyid: string;
    priceInfo?: PriceObject;
    xco: string;
    xitem: string;
}

interface CustomXrefItem {
    xrefItem: string;
    image?: string;
    company?: string;
    description?: string;
}

export interface FrequentlyBoughtTogether {
    items: OrderableItem[];
    itemTotal: number;
}

export interface GetTopProductResponse {
    dataset: TopProduct[];
    result: string;
}

interface InternalInventoryData {
    data?: string;
    email?: {subject: string; to: string};
    label: string;
    phone?: {label: string; number: string; url: string};
    type: string;
    url?: string;
}

export interface ItemBreadcrumb {
    categoryPath: string;
    name: string;
}

export interface ItemImages {
    id: string;
    image: string;
    imagePath?: string;
    item: string;
    itemID_s?: string;
    secondaryImages?: WebAsset[];
}

export interface LookAheadLink {
    categoryPath: string;
    linkText: string;
}

export type ManyPrices = {
    itemTotal: number;
} & {
    [item: string]: PriceObject;
};

export interface MediaItem {
    comment: string;
    filename: string;
    type: string;
}

export interface OrderableItem {
    actualSizeImageAsset?: string;
    actualSizeImageHeight?: string;
    actualSizeImageWidth?: string;
    actualSizeRequired?: boolean;
    backsheetProductTitle?: string;
    binLocation?: string;
    brand?: string;
    categoryPath?: string;
    checked?: boolean;
    crossRefFrom?: string;
    description?: string;
    excludeStArray?: string[];
    fullWebcat?: string;
    gdspId?: string;
    groupDescription?: string;
    gSeq?: number;
    id?: string;
    image?: string;
    imagePath?: string;
    inStock?: boolean;
    invnGroup?: string;
    isDirectReplacement?: boolean;
    isDiscontinued?: boolean;
    isSampled?: boolean;
    item: string;
    onOrder?: boolean;
    packageQuantity?: number;
    pandemicItem?: boolean;
    pandemicItemBlocked?: boolean;
    pkgQty?: number;
    prettyItem?: string;
    price?: string;
    priceC?: number;
    priceInfo?: PriceObject;
    quantity?: number;
    recToken?: string;
    scmt?: string;
    subbedFrom?: string;
    unitsOrdered?: number;
    webPrice?: WebPrice;
    wid?: number;
    widDescription?: string;
    xitem?: string;

    // Error state
    status?: number;
    statusText?: string;
}

export interface OrderableItemsRes {
    items: OrderableItem[];
}

export interface PrismicCategoryContent {
    responsive_banner_image: PrismicImage;
    banner_medium: PrismicImage;
    banner_small: PrismicImage;
    description: HtmlContent[];
    expiration_date: string;
    last_publication_date: string;
    url: string;
    webcatid: string;
}

interface PrintXrefItem {
    company: string;
    xrefItem: any; // string?
}

export interface ReStockEmailParams {
    account: string;
    email: string;
    item: string;
    login: string;
}

export interface ReStockEmailRes {
    email: string;
    msg: string[];
    result: string;
}

export interface ShareItemRes {
    msg: string[];
    result: string;
    sentTo: ShareLinkRecipient[];
}
export interface PkgQtyVariant {
    id: string;
    outOfStock: boolean;
    qty: number;
}
export interface STEPExtrinsic {
    text: string;
    value: string;
    valueID: string;
}
export interface StepFamilyAttribute {
    _id: string;
    displayType?: string;
    name: string;
    sortType?: string;
}
export interface StepAsset {
    id: string;
    image: string;
    name: string;
}
export interface StepFamilyChild {
    _id: string;
    childID?: string;
    discontinued: `Yes` | `No`;
    outOfStock?: boolean;
    dropdownExtValue?: STEPExtrinsic;
    dropdownValue?: string;
    dropdownAsset?: StepAsset;
    lastModified?: Date;
    name: string;
    objectTypeID: string;
    parentID: string;
    webPublished: `Yes` | `No`;
}

export interface STEPFamily {
    displayAttr: {
        renderType: string;
        field: string;
    };
    _id: string;
    children?: StepFamilyChild[];
    dropdownAttribute: StepFamilyAttribute;
    lastModified?: Date;
    name: string;
    parentID: string;
}

export interface SwitchAndSaveItem extends OrderableItem {
    amtDiff: number;
    compPrice: number;
    pctDiff: number;
}

export interface PriceInfo {
    bpriceC: number;
    bpriceCCalc: number;
    bpriceEA: number;
    bpricePkg: string;
    bpriceUnit: string;
    multiplier: number;
    priceBook: string;
    priceC: number;
    priceCCalc: number;
    priceEA: number;
    pricePkg: string;
    priceUnit: string;
    specialComment: string;
    specialNumber: string;
    success: string;
}

export interface GdspSdsAsset {
    _id: string;
    assetID: string;
    modDate: string;
}

export interface TopProduct {
    id: string;
    image: string;
    imagePath: string;
    name: string;
    ordersCnt: number;
    pkgQty: number;
    priceInfo?: PriceObject;
}

export interface WebAsset {
    _id: string;
    description?: string;
    fileLocation: string;
    name: string;
    referenceType: string;
    thumbLocation?: string;
    thumb400Location?: string;
    thumb1000Location?: string;
    metaData: {
        extension: string;
        filename: string;
        format: string;
        mimeType: string;
        size: string;
        uploadDt: string;
    };
}
export interface TechSpecValue {
    facetName: string;
    facetValue: string;
}
export interface AdditionalSpecValue {
    name: string;
    value: string;
}

export interface WebcatMeta {
    breadcrumbs: Breadcrumbs[];
    categoryPath: string;
    children?: WebcatMeta[];
    cleanName: string;
    cmsContent?: PrismicCategoryContent;
    content?: string;
    description: string;
    fullCleanName: string;
    graphic_image_url?: string;
    h1Override?: string;
    hasItems: boolean;
    id: number;
    imageAssetLargeThumb: string;
    imagePath: string;
    isTerminal: boolean;
    keywords: string;
    lookAheadLinks: LookAheadLink[];
    metaDescOverride?: string;
    metaRobots?: string;
    name: string;
    parent: number;
    parents?: WebcatMeta[];
    renderView: string;
    showExt: string;
    sortOrder?: number;
    splitPosition?: WebcatSplitPosition;
    stepID: string;
    stepImage?: string;
    titleTagOverride?: string;
}

export interface XrefItem {
    company: string;
    description: string;
    image: string;
    xrefcompany: string;
    xrefItem: string;
}

export interface IDPVariantChild {
    _id: string;
    childID?: string;
    discontinued: `Yes` | `No`;
    outOfStock?: boolean;
    dropdownAsset?: StepAsset;
    dropdownExtValue?: STEPExtrinsic;
    dropdownValue?: string;
}
export interface IDPVariant {
    _id: string;
    name: string;
    productValue: string;
    children: IDPVariantChild[];
    renderType: string;
    field: string;
    rank: number;
}

export class Item {
    alertMessage?: string;
    assortmentContains?: string[];
    inAssortmentsItems?: OrderableItem[];
    asstItems_s: string;
    baseBcTitle: string;
    baseBcURL: string;
    bin?: string;
    binLocation?: string;
    brand_name_s?: string;
    breadcrumbs: ItemBreadcrumb[];
    canPurchase: boolean;
    canShipAir: boolean;
    cat: string[];
    catalogPage: string;
    catalogPages_s_M: string[];
    catdesc: string[];
    checked?: boolean;
    ctry: string;
    customXrefItem?: CustomXrefItem;
    description: string;
    discontinued?: boolean;
    documents: string[];
    dropshipDays_i: number;
    error?: string; // Have not verified
    exceptions: Exceptions;
    excludeStArray: string[];
    expertItems?: OrderableItem[];
    trainingLinks?: {
        type: string;
        filename: string;
        comment: string;
    }[];
    pageIncludes?: {
        IB?: {html: string};
        NT?: {html: string};
    };
    frequentlyBoughtTogether?: FrequentlyBoughtTogether;
    ezid: number[];
    gdspType_s: string;
    graMsdsavail_b: string;
    green: boolean;
    group?: number;
    groupstring: string;
    gdspSdsAsset: GdspSdsAsset;
    GSDP_ID_s: string;
    h1HTML?: string;
    h1SeoOverride: string;
    hidden?: boolean;
    id: string;
    image: string;
    image_string: string;
    primaryImageDesc?: string;
    imagePath: string;
    images: string[];
    inAssortment: string[];
    inStock: boolean;
    invAdmin?: InternalInventoryData[];
    invMsg: string;
    invngroup: string;
    invngroupdesc: string;
    invStatus: string;
    isJustAdded?: boolean;
    isBestSeller_b: boolean;
    isdiscontinue: boolean;
    isLabeledInShop?: any;
    isNew: boolean;
    isPurchased?: boolean;
    isWeb: boolean;
    item?: string;
    itemID_s?: string;
    itemNeedsAppr: boolean;
    itemQtyLimit_i?: number;
    itemtype: `D` | `I` | `L`;
    labelPicture_i: number;
    lastInvcDt?: string;
    lastPurchase?: string;
    lastPurchaseInvoice?: string;
    lottracking: boolean;
    material_spec: string;
    material_spec_s: string;
    material_uom: string;
    media: MediaItem[];
    metaDescription?: string;
    msds_s: string;
    msdsavail: boolean;
    name: string;
    name_no_numbers: string;
    npLinks?: string;
    ondemandDays_i?: number;
    onHand: string;
    onSpecial_s_M: string[];
    ormd: boolean;
    pageTitle?: string;
    pandemicItem: boolean;
    pandemicItemBlocked: boolean;
    pdc: number;
    pkgQty: number;
    pkgQtyVariants?: PkgQtyVariant[];
    popmult: number;
    popularity: number;
    prettyid: string;
    prettyItem?: string;
    price: PriceObject;
    priceInfo: PriceObject;
    priceSubject_b: boolean;
    pricingStatus: `` | `Blocked` | `Convert`;
    printXrefItem?: PrintXrefItem;
    productNameWildcard: string;
    prop65b_b: boolean;
    prop65c_b: boolean;
    prop65Message?: string;
    psection: boolean;
    publicXref: XrefItem[];
    purchasedBy_s_M: string[];
    purchases?: number;
    qty: number;
    qtyLimit?: number;
    qtyOrdered?: number;
    requiredItems?: OrderableItem[];
    sds_s?: string;
    sdsAssetFileName_s: string;
    sdsavail_b: boolean;
    secondaryImages?: WebAsset[];
    shortdesc: string;
    snbFilter_b?: boolean;
    specText_txt: string[];
    spell: string;
    startDt: string;
    statColor: `red` | `green` | ``;
    substituteFor_s_M: string[];
    supportingDocuments?: WebAsset[];
    surcharge?: boolean;
    switchAndSaveID?: string;
    switchAndSaveItem?: SwitchAndSaveItem;
    tariffCode_s: string;
    termSpec?: {
        [extrinsic: string]: string;
    };
    TTCode_s: string;
    uglyItem: string;
    unitsOrdered?: number;
    upsHazard: boolean;
    variants?: IDPVariant[];
    webcatid: string;
    webPrice: WebPrice;
    weight_c_f: number;
    weight_ea_f: number;
    xref: string[];
    yourLabel?: any; // string?
    yourNumbers?: any[]; // string[]?
    techSpecs: TechSpecValue[];
    additionalSpecs?: AdditionalSpecValue[];

    constructor(itemData) {
        // Assign class properties
        for (const propName in itemData) {
            if (itemData.hasOwnProperty(propName)) {
                this[propName] = itemData[propName];
            }
        }

        // Transform data
        this._addPriceInfo();
        this._addPricingStatus();
        this._addYourXRefs();
        this._replaceOldTitle();
    }

    /**
     * Returns true if item can be auto-reordered
     */
    public canAutoReorder(user: User): boolean {
        // Do not allow punchout users
        if (user.isPunchout) {
            return false;
        }

        // Cannot auto-reorder discontinued items
        if (this.isdiscontinue) {
            return false;
        }

        // Cannot auto-reorder shipping saver (ICOM-2967)
        if (this.id === SHIPPING_SAVER) {
            return false;
        }

        // Can auto-reorder by default
        return true;
    }

    /**
     * Converts OrderableItem to Result for ItemListCardComponent
     * @param orderableItem
     */
    public static convertOrderableItemToResult(orderableItem: OrderableItem): Result {
        return {
            brand_name_s: orderableItem.brand,
            h1HTML: orderableItem.description,
            id: orderableItem.item,
            imagePath_s: orderableItem.imagePath,
            pandemicItemBlocked: orderableItem.pandemicItemBlocked,
            pkgQty: orderableItem.pkgQty,
            prettyid: orderableItem.prettyItem,
            priceInfo: orderableItem.priceInfo,
            statusText: orderableItem.statusText,
            subbedFrom: orderableItem.subbedFrom,
            unitsOrdered: orderableItem.unitsOrdered,
            exceptions: {
                canShipAir: null,
                id: orderableItem.id,
                ormd: null,
                prop65b_b: null,
                prop65c_b: null,
                prop65Message: null,
                sds_s: null,
                sdsAssetFileName_s: null,
                upsHazard: null,
            },
        };
    }

    /**
     * Converts Item to OrderableItem
     * @param item
     */
    public static convertItemToOrderableItem(item: Item): OrderableItem {
        return {
            item: item.id,
            description: item.name,
            id: item.id,
            imagePath: item.imagePath,
            pandemicItemBlocked: item.pandemicItemBlocked,
            pandemicItem: item.pandemicItem,
            pkgQty: item.pkgQty,
            prettyItem: item.prettyItem,
            priceInfo: item.priceInfo,
            statusText: item.invStatus,
            unitsOrdered: item.unitsOrdered,
            excludeStArray: item.excludeStArray,
        };
    }

    /**
     * TBD
     * @param orderableItems
     * @param removeConverts
     */
    public static formatItemsForDisplay(orderableItems: OrderableItem[], removeConverts?: boolean): OrderableItem[] {
        const itemsToDisplay = [];
        for (const orderableItem of orderableItems) {
            // Do not display BLOCKED and ERROR priced items
            if (orderableItem.priceInfo && (orderableItem.priceInfo.error === `BLOCKED` || orderableItem.priceInfo.errorType === `ERROR`)) {
                continue;
            }

            // Do not display CONVERTS
            if (
                orderableItem.priceInfo &&
                removeConverts &&
                (orderableItem.priceInfo.errorType === `CONVERT` || orderableItem.priceInfo.errorType === `ERROR`)
            ) {
                continue;
            }

            // Limit name to 50 characters
            const fiftyChars = 50;
            if (orderableItem.description && orderableItem.description.length > fiftyChars) {
                orderableItem.description = `${orderableItem.description.substring(0, fiftyChars)}...`;
            }

            // Add remaining updated items to itemsToDisplay
            itemsToDisplay.push(orderableItem);
        }
        return itemsToDisplay;
    }

    /**
     * Converts Result to ItemImages
     * @param result
     */
    public static getItemImagesFromResult(result: Result): ItemImages {
        return {
            id: result.id,
            image: result.image,
            imagePath: result.imagePath_s || `noimage.png`,
            item: result.id,
        };
    }

    /**
     * Tests if prettyItemId is valid
     * @param prettyItemId - prettyItemId to test
     */
    public static isValidPrettyItemID(prettyItemId: string): boolean {
        const validPrettyItemIDRegex = new RegExp(`^${prettyItemIDCaptureGroup}$`, `i`);
        return !!(prettyItemId && validPrettyItemIDRegex.test(prettyItemId));
    }

    /**
     * Tests if uglyItemId is valid
     * @param uglyItemId - uglyItemId to test
     */
    public static isValidUglyItemID(uglyItemId: string): boolean {
        const validUglyItemIDRegex = new RegExp(`^${uglyItemIDCaptureGroup}$`, `i`);
        return !!(uglyItemId && validUglyItemIDRegex.test(uglyItemId));
    }

    /**
     * Returns itemId in a way suitable for display
     * @param itemId
     */
    public static prettyItem(itemId: string): string {
        let charNotZero = false;
        let prettyItemNum = ``;
        let itemNumChar: string;
        for (let charPos = 0; charPos < itemId.length; charPos++) {
            itemNumChar = itemId.substring(charPos, charPos + 1);
            if (itemNumChar !== `0`) {
                charNotZero = true;
                if (charPos < 6) {
                    prettyItemNum = prettyItemNum + itemNumChar;
                } else {
                    prettyItemNum = prettyItemNum + (`-` + itemNumChar);
                }
            } else if (charNotZero === true && charPos < 6) {
                prettyItemNum = prettyItemNum + `0`;
            } else {
                prettyItemNum = prettyItemNum + ` `;
            }
        }
        return prettyItemNum.trim();
    }

    /**
     * Sorts provided assortment items by sortOrder
     * @param assortmentItems - Items to sort
     */
    public static sortAssortmentItems(assortmentItems: AssortmentItem[]) {
        const fieldName = `webcatName`;
        const sortedAssortmentItems = assortmentItems;

        // Sort assortmentItems
        sortedAssortmentItems.sort((assortmentItem1, assortmentItem2) => {
            if (
                (assortmentItem1[fieldName] != null) < (assortmentItem2[fieldName] != null) ||
                assortmentItem1[fieldName] < assortmentItem2[fieldName]
            ) {
                return -1;
            }
            if (
                (assortmentItem1[fieldName] != null) > (assortmentItem2[fieldName] != null) ||
                assortmentItem1[fieldName] > assortmentItem2[fieldName]
            ) {
                return 1;
            }
            return 0;
        });

        // Return sortedAssortmentItems
        return sortedAssortmentItems;
    }

    /**
     * Converts an item's prettyId to an uglyId
     * @param itemId - prettyId you wish to convert
     * @returns - Ugly version of the provided itemId
     */
    public static uglyItem(itemId: string): string {
        const imperialPrettyRegex = /^([A-Z0-9]{1,6})(-[A-Z0-9])?$/;
        const moreLikelyToBeImperial = /^([0-9]{1,6})$/;
        const graingerPartRegex = /^[0-9]{1,2}[A-Z0-9]{3}[0-9]$/;

        if (!itemId) {
            return ``;
        }

        itemId = itemId.toUpperCase().replace(`#`, ``);

        if (graingerPartRegex.test(itemId) && !moreLikelyToBeImperial.test(itemId)) {
            return itemId;
        }
        if (!imperialPrettyRegex.test(itemId)) {
            return itemId;
        }

        const regexResult = imperialPrettyRegex.exec(itemId);

        if (regexResult[2]) {
            regexResult[1] = regexResult[1] + regexResult[2].replace(`-`, ``);
        } else {
            regexResult[1] = regexResult[1] + `0`;
        }
        return `0000000`.substring(0, 7 - regexResult[1].length) + regexResult[1];
    }

    /**
     * Determines if the provided user can purchase this item
     * @param item - Item to evaluate
     * @param user - User to evaluate
     * @param order - Check if promo on order
     */
    public static userCanPurchase(item: Item, user: User, order: Order) {
        // Immediately disallow !canPurchase
        if (!item.canPurchase) {
            return false;
        }

        // Disallow punchout users performing an inspect operation
        if (user.isPunchout && user.operation === `inspect`) {
            return false;
        }

        // Disallow promo items on the order
        if (order) {
            for (const orderLine of order.allLines) {
                if (orderLine.promo && orderLine.item === item.id) {
                    return false;
                }
            }
        }

        // Default to true
        return true;
    }

    /**
     * Adds the `priceInfo` property to make it work with PriceComponent
     * @private
     */
    private _addPriceInfo() {
        this.priceInfo = this.price;
    }

    /**
     * Evaluates and adds canPurchase, pricingStatus
     * @private
     */
    private _addPricingStatus() {
        // Defaults
        let priceCanPurchase = true;
        this.pricingStatus = ``;

        // Price Yes
        if (this.price) {
            priceCanPurchase = true;
        }

        // Price No
        if (this.price && this.price.errorType === `ERROR`) {
            priceCanPurchase = false;
        }

        // Convert
        if (this.price && this.price.errorType === `CONVERT` && this.price.error !== `BLOCKED`) {
            priceCanPurchase = true;
            this.pricingStatus = `Convert`;
        }

        // Block
        if (this.price && this.price.errorType === `CONVERT` && this.price.error === `BLOCKED`) {
            priceCanPurchase = false;
            this.pricingStatus = `Blocked`;
        }

        // Discontinued items without stock
        if (this.isdiscontinue && (this.inStock === false || this.itemtype === 'D')) {
            priceCanPurchase = false;
        }
        if (this.canPurchase !== false) {
            this.canPurchase = priceCanPurchase;
        }
    }

    /**
     * Adds yourLabel, yourNumbers (customer specific xrefs)
     * @private
     */
    private _addYourXRefs() {
        if (this.customXrefItem || this.printXrefItem) {
            const yourNumbers = [];
            if (this.customXrefItem) {
                yourNumbers.push(this.customXrefItem.xrefItem);
            }
            if (this.printXrefItem) {
                yourNumbers.push(this.printXrefItem.xrefItem);
                let yourLabel = this.printXrefItem.xrefItem;
                if (yourLabel.length < 25) {
                    const trimmedName = this.printXrefItem.company.substring(0, 24 - yourLabel.length);
                    yourLabel = `${trimmedName}-${this.printXrefItem.xrefItem}`;
                }
                this.yourLabel = yourLabel;
            }
            if (yourNumbers.length > 0) {
                this.yourNumbers = uniq(yourNumbers);
            }
        }
    }

    /**
     * Removes oldTitle information from description (ref ICOM-2340)
     * @private
     */
    private _replaceOldTitle() {
        const oldTitleRegex = /^(<br>)?<div class="prodtitle">.*?(?=(<\/div>))<\/div>/i;
        if (oldTitleRegex.test(this.description)) {
            this.description = this.description.replace(oldTitleRegex, ``);
        }
    }
}
